$(document).ready(function(){
    var showcaseBanner = $('#banner-1-primary');
    if (showcaseBanner.length) {
        showcaseBanner.owlCarousel({
            loop: true,
            items: 1,
            dots: true,
            autoplayTimeout: 10000,
            nav: false,
            autoplay: true
        });
    }

    var storesBanner = $('#stores-carousel');
    if (storesBanner.length) {
        storesBanner.owlCarousel({
            loop: true,
            items: 6,
            dots: false,
            autoplayTimeout: 10000,
            nav: true,
            autoplay: true,
        });
    }
});
